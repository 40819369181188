var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('ph-navigation'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"altbg"}),_c('subscribe'),_c('blog'),_c('ph-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header"},[_c('div',[_c('h1',[_vm._v("The most immersive "),_c('strong',[_vm._v("cyber security training")])]),_c('div',{staticClass:"header__subtitle"},[_vm._v("Performance. Elevated.")])]),_c('img',{attrs:{"src":require("@/assets/images/HeadsetAR6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"keypoints"},[_c('div',{staticClass:"keypoint"},[_c('strong',{staticClass:"keypoint__value"},[_vm._v("4x")]),_c('p',{staticClass:"keypoint__description"},[_vm._v("Faster to train AR in\u2028 the classroom")])]),_c('div',{staticClass:"keypoint"},[_c('strong',{staticClass:"keypoint__value"},[_vm._v("2.75x")]),_c('p',{staticClass:"keypoint__description"},[_vm._v("More confindent to apply skills learned after training")])]),_c('div',{staticClass:"keypoint"},[_c('strong',{staticClass:"keypoint__value"},[_vm._v("3.75x")]),_c('p',{staticClass:"keypoint__description"},[_vm._v("More emotionally connected to content than classroom learners")])]),_c('div',{staticClass:"keypoint"},[_c('strong',{staticClass:"keypoint__value"},[_vm._v("4x")]),_c('p',{staticClass:"keypoint__description"},[_vm._v("More focused than their e-learning peers")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showcase showcase--reversed"},[_c('div',{staticClass:"showcase__content"},[_c('h2',[_vm._v("Raise cyber awareness in the most immersive way")]),_c('p',[_vm._v("Our unique approach to employee training comes from combining our cyber security expertise with the upcoming mixed reality.")]),_c('p',[_vm._v("The approach allows us to immerse your employees to the unforseen level of reality, right in front of their office desk.")])]),_c('div',{staticClass:"showcase__visual"},[_c('div',{staticStyle:{"padding":"56% 0 0 0","position":"relative"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":"https://player.vimeo.com/video/777268612?h=f66c40a7c4&badge=0&loop=1;autoplay=1&player_id=0&app_id=58479","frameborder":"0","allow":"autoplay;","allowfullscreen":"","title":"AR training"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"benefits"},[_c('h2',{staticClass:"benefits__title"},[_vm._v("Augmented reasoning")]),_c('div',{staticClass:"benefits__list"},[_c('div',{staticClass:"benefit"},[_c('h3',[_vm._v("Employee personalisation")]),_c('p',[_vm._v("To increase the memorability of simulated attacks, we use Dynamic script synthetisation. Your office employee names end up in our voiceovers.")])]),_c('div',{staticClass:"benefit"},[_c('h3',[_vm._v("Real life scenarios")]),_c('p',[_vm._v("Educational scenarios are based on the most common workplace mistakes that compromise entire companies.")])]),_c('div',{staticClass:"benefit"},[_c('h3',[_vm._v("Tailored experiences")]),_c('p',[_vm._v("Admin interface allows you to pick and choose various attack vectors you want to educate your employees. It’s a simple click away.")])]),_c('div',{staticClass:"benefit"},[_c('h3',[_vm._v("Smart reporting")]),_c('p',[_vm._v("Review employee training scores and receive aggregated resilience scoring to detect the weakest points in your security posture early on.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"partners"},[_c('span',[_vm._v("Our partners and clients")]),_c('img',{attrs:{"src":require("@/assets/images/partners.png"),"alt":"partners"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"showcase"},[_c('div',{staticClass:"showcase__visual"},[_c('img',{attrs:{"src":require("@/assets/images/VisualPlaceholder.png"),"alt":""}})]),_c('div',{staticClass:"showcase__content"},[_c('h2',[_vm._v("Prevent cyber attacks")]),_c('p',[_vm._v("Using immersive technologies for cyber security training allows your employees to experience threats in the most immersive and memorable way.")]),_c('p',[_vm._v("Embrace the power of immersive technologies today to ensure no threats slip through your human firewall.")])])])
}]

export { render, staticRenderFns }