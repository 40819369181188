<template>

  <div class="subscribe">

    <div class="subscribe__overlay overlay" :class="{ 'overlay--visible': focused }"></div>
    <div class="subscribe__overlay getstarted" id="getstarted" :class="{ 'getstarted--focused': focused }">

      <h2>Sign up to stay in touch</h2>
      <template v-if="!subscribed">

      <p>We will keep you updated and get back to you shortly</p>
        <form class="getstarted__form" :class="{ 'getstarted__form--valid': validEmail }" @submit.prevent="subscribe">

          <input type="text" name="name" value="" class="getstarted__name" v-model="name">
          <input
            type="text"
            placeholder="Your email"
            @focus="focusStarted"
            @blur="focusEnded"
            v-model="email"
          >
          &nbsp;
          <button type="submit" name="button" class="cta">&rarr; Sign up</button>

        </form>
        <!-- <small>By submitting this your email, you accept our Terms and conditions.</small> -->
      </template>
      <div v-else>
        <img src="@/assets/images/icon-success.png" alt="success">
        <p>Subscribed! We will get back to you as soon as possible more info.</p>
      </div>

    </div>

  </div>

</template>
<script>
import axios from 'axios';
const apiUrl = 'https://us-central1-cyberxr.cloudfunctions.net/subscribe';
export default {
  data() {
    return {
      focused: false,
      email: '',
      name: '',
      subscribed: false
    }
  },
  computed: {
    validEmail() {
      return (/^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
    }
  },
  methods: {
    focusStarted() {
      this.focused = true
    },
    focusEnded() {
      this.focused = false
    },
    subscribe() {

      if (!this.validEmail) {
        return false;
      }

      const data = new URLSearchParams();
      data.append('email', this.email);
      data.append('name', this.name);

      axios({
        method: 'post',
        url: apiUrl,
        timeout: 10000,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
      }).then(response => {
        console.log('response', response);
      }).catch(error => {
        console.error(error);
      });

      this.subscribed = true

    }
  }
}
</script>
