<template>
  <div id="app">
    <router-view :key="$route.path" />
    <cookie />
  </div>
</template>
<script>
import Cookie from '@/components/Cookie'
export default {
  components: {
    Cookie
  }
}
</script>
