<template>
  <div class="navigation">

    <router-link :to="{ name: 'home' }" class="navigation__logo">
      <img src="@/assets/images/phishar_logo.svg" alt="">
    </router-link>
    <nav class="navigation__list">
      <ul>
        <li><router-link :to="{ name: 'home' }" :class="{ 'active': $route.name === 'home' }">XR Immersive training</router-link></li>
        <li><a href="https://lokot.app" target="_blank">Companion app</a></li>
        <li><router-link :to="{ name: 'about' }" :class="{ 'active': $route.name === 'about' }">About</router-link></a></li>
        <li class="onMobile"><router-link :to="{ name: 'home', hash: '#getstarted' }" class="cta">Get access</router-link></li>
        <li><a href="https://app.oxlens.com" target="_blank">&rarr; Launch XR</a></li>
      </ul>
    </nav>

  </div>
</template>
<script>
export default {}
</script>
